import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';

import 'typeface-roboto';
import { Container } from '@material-ui/core';

const theme = createMuiTheme({
  spacing: 4,
  palette: {
    primary: {
      main: '#1e88e5',
    },
    secondary: {
      main: '#0091ea',
    },
  },
});
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    direction: "column",
  },
  paper: {
    width:200,
  },
  paperTypography: {
    textAlign: "center",
    marginLeft: 20,
    marginRight: 20,
  },
  playerAvatar: {
    justifySelf:"center",
    justify:"center",
    margin:10,
    width:100,
    height:100,
  },
  
}));

function App() {
  const classes = useStyles();
  return (
  <Grid container justify="center" alignItems="center" spacing={theme.spacing(1)} >
    {players.map(players => (
      <Grid key={players.name} item >
        <Card >
          <CardContent alignItems="center" direction="row" justify="center">
            <Grid container justify="center" alignSelf="center">
              <Avatar className={classes.playerAvatar} src={players.ImageLoc} />
            </Grid>
            
            <Typography variant="h5" component="h3" class={classes.paperTypography}>
              {players.FirstName} "{players.Nick}" {players.LastName}
            </Typography>
            <Typography component="p" class={classes.paperTypography}>
              {players.Subject}
            </Typography>
            <Typography component="p" class={classes.paperTypography}>
              {players.Description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
      
  );
}
const players = [
  {"FirstName":"Jaziel","Nick":"Elemental","LastName":"Perez", "Subject":"The 9 RWS Bot", "Description": "Single Digit Andy", "ImageLoc":"ghimli.png"},
  {"FirstName":"Ahmaad","Nick":"Mod","LastName":"Lumpkin", "Subject":"The Alien", "Description": "The 6 Time", "ImageLoc":"modlotr.png"},
  {"FirstName":"Phillipe","Nick":"Telegraphic","LastName":"Weeks", "Subject":"The Italian", "Description": "Bug", "ImageLoc":"jakelotr.png"},
  {"FirstName":"Bradley","Nick":"Retard","LastName":"Tait", "Subject":"The KKona", "Description": "FIFA Normie", "ImageLoc":"bradlotr.png"},
  {"FirstName":"Alex","Nick":"tait13","LastName":"Tait", "Subject":"The Yellow Teeth", "Description": "Future Wizard", "ImageLoc":"taitlotr.png"},
];
export default App;

{/* <Grid container justify="center" spacing={theme.spacing(1)} >
  {players.map(players => (
    <Grid key={players.name} item>
      <Paper className={classes.paper}>
        <Avatar className={classes.playerAvatar} src={players.ImageLoc} item />
        <Typography variant="h5" component="h3" class={classes.paperTypographyPar}>
          {players.FirstName} "{players.Nick}" {players.LastName}
        </Typography>
        <Typography component="p" class={classes.paperTypographyPar}>
          {players.Subject}
        </Typography>
        <Typography component="p" class={classes.paperTypographyPar}>
          {players.Description}
        </Typography>
      </Paper>
    </Grid>
  ))}
</Grid> */}